<template>
  <!-- Infos für den Redakteur
  - Texte in i18n/de.json und i18n/en.json einbauen
  - Entsprechend Beispiel des Titels einbinden
  - Gestaltungselemente: https://vuetifyjs.com/en/components/cards und weitere Seiten dort
  -->
  <div>
    <v-card>
      <v-card-title>
        {{ $t('infoforsysop.title') }}
      </v-card-title>
      <v-card-text>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.1') }}
        </p>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.2') }}
        </p>
        <ul>
          <li>{{ $t('infoforsysop.texts.3-1') }}</li>
          <li>{{ $t('infoforsysop.texts.3-2') }}</li>
          <li>{{ $t('infoforsysop.texts.3-3') }}</li>
        </ul>
        <br />
        <p class="text-justify">
          {{ $t('infoforsysop.texts.5') }}
        </p>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.6') }}
        </p>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.7-1') }}
          <a href="https://live.nordwestserver.info">https://live.nordwestserver.info</a>
          {{ $t('infoforsysop.texts.7-2') }}
        </p>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.8') }}
        </p>
        <p class="text-justify">
          {{ $t('infoforsysop.texts.9') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "InfoForSysops"
}
</script>

<style scoped>

</style>