<template>
  <!-- Infos für den Redakteur
  - Texte in i18n/de.json und i18n/en.json einbauen
  - Entsprechend Beispiel des Titels einbinden
  - Gestaltungselemente: https://vuetifyjs.com/en/components/cards und weitere Seiten dort
  -->
  <div>
    <v-card>
      <v-card-title>
        {{ $t('impress.title') }}
      </v-card-title>
      <v-card-text>
        <h3 class="text-justify">
          {{ $t('impress.texts.1') }}
        </h3>
        <br/>
        <p class="text-justify">
          {{ $t('impress.texts.2-name') }} <br/>
        </p>
        <p class="text-justify">
          {{ $t('impress.texts.contact') }} <br/>
        </p>
        <br/>
        <h3 class="text-justify">
          {{ $t('impress.texts.disclaimer.title') }}
        </h3>
        <br/>
        <h4 class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.content.title') }}
        </h4>
        <p class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.content.text') }} <br/>
        </p>
        <br />
        <h4 class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.links.title') }}
        </h4>
        <p class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.links.text') }} <br/>
        </p>
        <br />
        <h4 class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.copyright.title') }}
        </h4>
        <p class="text-justify">
          {{ $t('impress.texts.disclaimer.liability.copyright.text') }} <br/>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Impress",
  components: { }
}
</script>

<style scoped>

</style>