import Vue from 'vue'
import VueRouter from 'vue-router'
import NWSLive from "../components/NWSLive";
import Impress from "../components/Impress";
import InfoForSysops from "../components/InfoForSysops";
import AboutProject from "../components/AboutProject";
import Login from "../components/Login";
import Logout from "../components/Logout";
import RelaisTable from "../components/RelaisTable";
import RelaisNewEdit from "../components/RelaisNewEdit";
import Privacy from "../components/Privacy";
import UploadImage from "../components/UploadImage";
import NewsEdit from "../components/NewsEdit";
Vue.use(VueRouter)

export default new VueRouter({
    routes : [
        {
            path: '/',
            name: 'NWSLive',
            component: NWSLive,
            meta: {
                titleTranslationKey: 'pagetitle.NWSLive',
                requireAuthentication: false
            }
        },
        {
            path: '/impress',
            name: 'Impress',
            component: Impress,
            meta: {
                titleTranslationKey: 'pagetitle.impress',
                requireAuthentication: false
            }
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: Privacy,
            meta: {
                titleTranslationKey: 'pagetitle.privacy',
                requireAuthentication: false
            }
        },
        {
            path: '/about',
            name: 'About',
            component: AboutProject,
            meta: {
                titleTranslationKey: 'pagetitle.aboutproject',
                requireAuthentication: false
            }
        },
        {
            path: '/infoforsysops',
            name: 'InfoForSysops',
            component: InfoForSysops,
            meta: {
                titleTranslationKey: 'pagetitle.infoforsysops',
                requireAuthentication: false
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                titleTranslationKey: 'pagetitle.login',
                requireAuthentication: false
            }
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                titleTranslationKey: 'pagetitle.logout',
                requireAuthentication: false
            }
        },
        {
            path: '/relais',
            name: 'RelaisTable',
            component: RelaisTable,
            meta: {
                titleTranslationKey: 'pagetitle.relaistable',
                requireAuthentication: false
            }
        },
        {
            path: '/relais/new',
            name: 'New Relais',
            component: RelaisNewEdit,
            meta: {
                titleTranslationKey: 'pagetitle.relaisnew',
                requireAuthentication: false
            }
        },
        {
            path: '/relais/edit/:id',
            name: 'Edit Relais',
            component: RelaisNewEdit,
            meta: {
                titleTranslationKey: 'pagetitle.relaisedit',
                requireAuthentication: false
            }
        },
        {
            path: '/relais/uploadimage/:id',
            name: 'Upload Image',
            component: UploadImage,
            meta: {
                titleTranslationKey: 'pagetitle.uploadimage',
                requireAuthentication: false
            }
        },
        {
            path: '/newsedit',
            name: 'NewsEdit',
            component: NewsEdit,
            meta: {
                titleTranslationKey: 'pagetitle.newsedit',
                requireAuthentication: false
            }
        },
    ]
});
