<template>
  <v-container fluid>
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        {{ this.isEditMode ? this.$t('pagetitle.relaisedit') + ': ' + RelaisCandidate.callsign :
          this.$t('pagetitle.relaisnew') }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="isFormValid" ref="form">
            <v-row>
              <v-col v-if="!isEditMode">
                <v-text-field
                    v-model="RelaisCandidate.callsign"
                    :label="$t('relais.headers.callsign')"
                    counter="8"
                    required
                    type="text"
                    :rules="validationRules.callsign"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.desc"
                    :label="$t('relais.headers.description')"
                    counter="50"
                    required
                    type="text"
                    :rules="validationRules.description"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.url"
                    :label="$t('relais.headers.url')"
                    type="text"
                    :rules="validationRules.url"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.eirp_watt"
                    :label="$t('relais.edit.eirp') + '  [W]'"
                    required
                    type="number"
                    :rules="validationRules.eirp"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.antHeight_m"
                    :label="$t('relais.headers.antheight') + ' [m]'"
                    required
                    type="number"
                    :rules="validationRules.antheight"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.qrgtx"
                    :label="$t('relais.headers.frequencytx') + ' [Hz]'"
                    required
                    type="number"
                    :rules="validationRules.frequency"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.qrgrx"
                    :label="$t('relais.headers.frequencyrx') + ' [Hz]'"
                    required
                    type="number"
                    :rules="validationRules.frequency"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                    v-model="RelaisCandidate.ctcss"
                    :items="ctcssSelect"
                    item-text="text"
                    item-value="value"
                    :label="$t('relais.headers.ctcss')"
                    >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.Lat"
                    :label="$t('relais.edit.lat') + ' [°]'"
                    required
                    type="number"
                    :rules="validationRules.lat"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="RelaisCandidate.Long"
                    :label="$t('relais.edit.long') + ' [°]'"
                    required
                    type="number"
                    :rules="validationRules.long"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <div style="height: 400px; width: 100%;">
              <l-map
                  :zoom="mapZoom"
                  :center="mapCenter"
                  :options="mapOptions"
                  style="height: 400px; width: 100%; z-index: 1"
                  @click="handleMapClick"
              >
                <l-control-attribution position="bottomleft" prefix=""></l-control-attribution>
                <l-tile-layer
                    :url="this.$store.getters.url_tiles"
                    :attribution="mapAttribution"
                />
                <l-marker
                    :lat-lng="getMarkerlatLong"
                >
                </l-marker>
              </l-map>
              </div>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                    v-model="RelaisCandidate.enabled"
                    :label="$t('relais.headers.enabled')"
                >
                </v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                    v-model="RelaisCandidate.photoPresent"
                    :label="$t('relais.edit.photopresent')"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="btnCancel"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
            :disabled="!isFormValid"
            color="red accent-2"
            @click="btnSave"
        >
          {{ $t('general.save') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { latLng } from "leaflet";
import {LMap, LTileLayer, LMarker, LControlAttribution} from "vue2-leaflet";

export default {
  name: "RelaisNewEdit",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlAttribution
  },
  computed: {
    getMarkerlatLong () {
      if (this.RelaisCandidate.Lat && this.RelaisCandidate.Long) {
        return (latLng(this.RelaisCandidate.Lat, this.RelaisCandidate.Long));
      } else {
        return (latLng(53.5, 4));
      }
    },
    validationRules() {
      return {
        'description': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('relais.headers.description')}),
          v => (v && v.length >= 2) || this.$t('formvalidation.underlength', {
            fieldname: this.$t('relais.headers.description'),
            count: '2'
          }),
          v => (v && v.length <= 50) || this.$t('formvalidation.overlength', {
            fieldname: this.$t('relais.headers.description'),
            count: '50'
          })
        ],
        'url': [
          v => (!!v && (v.startsWith('http://') || v.startsWith('https://'))) || this.$t('formvalidation.mustbeurl')
        ],
        'eirp': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('relais.headers.eirp')}),
          v => (!!v && v > 0) || this.$t('formvalidation.notzeroornegative', {
            fieldname: this.$t('relais.headers.eirp')
          }),
          v => (!!v && v <= 1000) || this.$t('formvalidation.eirpnotgreater1kw', {
            fieldname: this.$t('relais.headers.eirp')
          })
        ],
        'antheight': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('relais.edit.antheight')}),
          v => (!!v && v > 0) || this.$t('formvalidation.notzeroornegative', {
            fieldname: this.$t('relais.edit.antheight')
          }),
          v => (!!v && v <= 400) || this.$t('formvalidation.antheigthnotgreater400m', {
            fieldname: this.$t('relais.edit.antheight')
          })
        ],
        'frequency': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('relais.edit.frequency')}),
          v => (!!v && v >= 144000000 && v <= 1300000000) || this.$t('formvalidation.OutOfRange', {
            fieldname: this.$t('relais.edit.frequency'),
            min: '144 MHz',
            max: '1300 MHz'
          })
        ],
        'callsign': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('relais.headers.callsign')}),
          v => (!!v && (v.endsWith('-R') || v.endsWith('-L'))) || this.$t('formvalidation.repeaterorlink'),
          v => (v && v.length <= 8) || this.$t('formvalidation.overlength', {
            fieldname: this.$t('relais.headers.callsign'),
            count: '8'
          })
        ],
      }
    }
  },
  created() {

  },
  mounted() {
    this.prepareFormData();
  },
  methods: {
    prepareFormData() {
      if (this.isEditMode) {
        this.getRelais(this.$route.params.id);
      } else {
        this.RelaisCandidate = Object.assign({}, this.RelaisEmpty);
      }

      let ctcssraw = require ('../ctcss.json');
      console.log('ctcssraw', ctcssraw);
      this.ctcssSelect = [{
        'text': 'Kein',
        'value': null
      }];
      let i;
      for (i = 0; i < ctcssraw.values.length; i++) {
        this.ctcssSelect.push({
          'text': ctcssraw['values'][i].toString() + ' Hz',
          'value': ctcssraw['values'][i]
        });
      }
      console.log('ctcssSelect', this.ctcssSelect);
    },
    getRelais(callsign) {
      this.isLoadingRelais = true;
      this.RelaisCandidate = {};
      this.RelaisOriginal = {};
      this.axios.get('/api/relais/' + callsign)
          .then((response) => {
            this.RelaisCandidate = response.data;
            this.RelaisOriginal = Object.assign({}, this.RelaisCandidate);
            this.mapCenter = latLng(this.RelaisCandidate.Lat, this.RelaisCandidate.Long);
            this.RelaisCandidate.qrgrx *= 1000000;
            this.RelaisCandidate.qrgtx *= 1000000;
            this.isLoadingRelais = false;
            console.log('getrelais', this.RelaisCandidate);
          });
    },
    btnCancel() {
      this.RelaisCandidate = {};
      this.$router.push('/relais');
    },
    btnSave() {
      let data2send = Object.assign({}, this.RelaisCandidate);
      data2send['qrgtx'] /= 1000000;
      data2send['qrgrx'] /= 1000000;
      data2send['callsign'] = data2send['callsign'].toUpperCase();
      console.log('data2send', data2send);
      console.log('isEditmode', this.isEditMode);
      if (this.isEditMode) {
        this.axios.post('/api/relais',
            data2send,
            {
              headers: {
                'Authorization': 'Basic ' + this.$store.getters.auth
              }
            })
            .then((response) => {
                console.log('POST relais ok', data2send, response);
                this.$router.push('/relais');
            });
      } else {
        this.axios.put('/api/relais',
            data2send,
            {
              headers: {
                'Authorization': 'Basic ' + this.$store.getters.auth
              }
            })
            .then((response) => {
                console.log('PUT relais ok', data2send, response);
                this.$router.push('/relais');
            });
      }

    },
    handleMapClick(event) {
      this.RelaisCandidate.Lat = Math.round(event.latlng.lat * 10000000) / 10000000;
      this.RelaisCandidate.Long = Math.round(event.latlng.lng * 10000000) / 10000000;
      //console.log('handleMapClick', event.latlng);
    }
  },
  data() {
    return {
      isEditMode: (!!(this.$route.params.id)),
      RelaisCandidate: {},
      RelaisOriginal: {},
      isLoadingRelais: false,
      isFormValid: false,
      RelaisEmpty: {
        'callsign': '',
        'Lat': null,
        'Long': null,
        'desc': '',
        'url': '',
        'qrgtx': null,
        'qrgrx': null,
        'ctcss': null,
        'photoPresent': false,
        'enabled': true,
        'eirp_watt': null,
        'antHeight_m': null,
        'LastSimulationTimestamp': null
      },
      ctcssSelect: [],
      mapZoom: 7,
      mapCenter: latLng(52.7, 8),
      mapOptions: {
        zoomSnap: 0.5
      },
      mapAttribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    };
  }
}
</script>

<style scoped>

</style>