<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
        <v-container fluid>
          <v-row align="center"
                 justify="center">
            <v-col>
              {{ $t('general.title') }}
            </v-col>
            <v-col>
              <v-img
                  max-width="63"
                  max-height="63"
                  src="/nordwestlink_logo_64.png"
                  class="ml-2 my-0"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar-title>

      <v-toolbar-title v-if="$vuetify.breakpoint.sm">
        <v-container fluid>
          <v-row align="center"
                 justify="center">
            <v-col>
              {{ $t('general.title_sm') }}
            </v-col>
            <v-col>
              <v-img
                  max-width="63"
                  max-height="63"
                  src="/nordwestlink_logo_64.png"
                  class="ml-2 my-0"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar-title>
      <v-toolbar-title v-if="$vuetify.breakpoint.xs">
        <!--{{ $t('general.title_xs') }}-->
        <v-img
            max-width="50"
            max-height="50"
            src="/nordwestlink_logo_64.png"
            class="ml-2 my-0"
        ></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- usercount -->
      <div v-if="$route.fullPath == '/'">
        <v-chip v-if="mqtt_constatus"
                class="ma-2"
                color="green"
                text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-eye-outline</v-icon>
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            {{ usercount }} {{ $t('status.viewer') }}
          </div>
          <div v-else>
            {{ usercount }}
          </div>
        </v-chip>
        <!--MQTT Status display-->
        <v-chip v-if="mqtt_constatus"
            class="ma-2"
            color="green"
            text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-cloud-check-outline</v-icon>
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            {{ $t('status.live') }}
              (E:{{ connectedStatistics.echolink }} 26:{{ connectedStatistics.TG26}} 49:{{ connectedStatistics.TG49 }} T:{{ connectedStatistics.total }})
          </div>
        </v-chip>
        <v-chip v-if="!mqtt_constatus"
                class="ma-2"
                color="red"
                text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-cloud-off-outline</v-icon>
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            {{ $t('status.notlive') }}
          </div>
          <div v-else>Live</div>
        </v-chip>

        <!--PTT Any display-->
        <v-chip v-if="PTT_Anystatus"
                class="ma-2"
                color="green"
                text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-access-point</v-icon>
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            {{ $t('status.txon') }}
          </div>
        </v-chip>
        <v-chip v-if="!PTT_Anystatus"
                class="ma-2"
                color="red"
                text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-access-point-off</v-icon>
          </v-avatar>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            {{ $t('status.alltxoff') }}
          </div>
        </v-chip>
      </div>
      <!-- Language select -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
              color="primary"
              dark
              text
              v-on="on"
          >
            <span :class="['flag-icon', `flag-icon-${$store.getters.languageFlag}`]"></span> {{ $store.getters.language }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="changeLanguage(item.value)"
          >
            <v-list-item-title>
              <span :class="['flag-icon', `flag-icon-${item.icon.toLowerCase()}`]"></span> {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Main Menu -->
      <v-menu
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list
            dense
            nav
        >
          <!-- Map -->
          <v-list-item link exact to="/">
            <v-list-item-icon>
              <v-icon>mdi-map-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('menu.map') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Relais Overview -->
          <v-list-item link exact to="/relais">
            <v-list-item-icon>
              <v-icon>mdi-table-large</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.relaistable') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- About -->
          <v-list-item link exact to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Info4Sysop -->
          <v-list-item link exact to="/infoforsysops">
            <v-list-item-icon>
              <v-icon>mdi-head-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.infoforsysops') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Legende -->
          <v-list-item
              @click="showLegend=true"
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.legend') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Thankyou -->
          <v-list-item
              @click="showcoffeedialog=true"
          >
            <v-list-item-icon>
              <v-icon>mdi-hand-heart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.thankyou') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Ratings -->
          <v-list-item
              @click="showRatings()"
          >
            <v-list-item-icon>
              <v-icon>mdi-star-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.ratings') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Impress -->
          <v-list-item link exact to="/impress">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.impress') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Privacy -->
          <v-list-item link exact to="/privacy">
            <v-list-item-icon>
              <v-icon>mdi-book-open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.privacy') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ADMIN: News Edit -->
          <v-list-item
              v-if="$store.getters.isAdmin"
              link exact to="/newsedit"
          >
            <v-list-item-icon>
              <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.newsedit') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Login -->
          <v-list-item v-if="!$store.getters.credentialsPresent" link exact to="/login">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.login') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Logout -->
          <v-list-item v-if="$store.getters.credentialsPresent" link exact to="/logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPath"></router-view>
        <!--<app-footer></app-footer>-->
      </v-container>
    </v-main>

    <!-- Bottom Sheet Legende -->
    <v-bottom-sheet
        hide-overlay
        v-model="showLegend"
    >
      <v-sheet>
        <v-card>
          <v-card-title>
            {{ $t('general.legend') }}
          </v-card-title>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-img src="/repeater_connected_26.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.repeater_connected_26') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/repeater_connected_49.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.repeater_connected_49') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/repeater_connected_2649.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.repeater_connected_2649') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/repeater_EcholinkNotIn26.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.repeater_EcholinkNotIn26') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/repeater_NotTG26orTG49.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.repeater_NotTG26orTG49') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/link_connected_26.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.link_connected_26') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/link_connected_49.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.link_connected_49') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/link_connected_2649.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.link_connected_2649') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/activerxing.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.rxing') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-img src="/server.png"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('legend.rxing') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-row justify="center">
              <v-btn
                  color="primary"
                  @click="showLegend = false"
              >
                {{ $t('general.close') }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Bottom Sheet Rating -->
    <v-bottom-sheet
        hide-overlay
        v-model="showratingsummary"
    >
      <v-sheet>
        <v-card>
          <v-card-title>
            {{ $t('menu.ratings') }} ({{ averageRating }})
          </v-card-title>
          <v-list>
            <v-list-item
                v-for="(item, index) in ratings"
                :key="index"
            >
              <v-list-item-icon>
                <v-rating
                    :value="index+1"
                    color="orange"
                    readonly
                ></v-rating>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip label>
                    {{ item }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                  color="primary"
                  @click="showratingsummary = !showratingsummary"
              >
                {{ $t('general.close') }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Bottom Sheet Cookie -->
    <v-bottom-sheet
        hide-overlay
        v-model="showcookiehint"
    >
      <v-sheet>
        <v-card>
          <v-card-title>
            {{ $t('cookiehint.title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('cookiehint.text') }} <v-btn color="primary" to="/privacy">{{ $t('privacy.title') }}</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn
                @click="bntcookieok"
                color="primary"
            >{{ $t('cookiehint.consent') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Footer Hamnet-->
    <v-footer app v-if="$vuetify.breakpoint.mdAndUp && $store.getters.amateurradiocontext">
      {{ $t('general.createdby') }} Ralf Wilke DH3WR Version {{ $store.getters.appVersion }}, {{ $t('general.supportedby') }}.
    </v-footer>
    <v-footer app v-if="$vuetify.breakpoint.smAndDown && $store.getters.amateurradiocontext">
      {{ $t('general.createdby') }} Ralf Wilke DH3WR Version {{ $store.getters.appVersion }}
    </v-footer>
    <!-- Footer Internet-->
    <v-footer app v-if="$vuetify.breakpoint.mdAndUp && !$store.getters.amateurradiocontext">
      {{ $t('general.createdby') }} <a style="margin-right: 5px; margin-left: 5px" href="https://www.ralfwilke.com" target="_blank_"> Wilke Engineering Consulting GmbH </a> Version {{ $store.getters.appVersion }}, {{ $t('general.supportedby') }}.
    </v-footer>
    <v-footer app v-if="$vuetify.breakpoint.smAndDown && !$store.getters.amateurradiocontext">
      {{ $t('general.createdby') }} <a style="margin-right: 5px; margin-left: 5px" href="https://www.ralfwilke.com" target="_blank_"> Wilke Engineering Consulting GmbH </a> Version {{ $store.getters.appVersion }}
    </v-footer>
    <!-- Rate dialog -->
    <v-dialog v-model="showratedialog">
      <v-card
          class="elevation-16 mx-auto"
          width="300"
      >
        <v-card-title class="headline">
          {{ $t('rating.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('rating.text') }}

          <div class="text-center mt-12">
            <v-rating
                v-model="ratinggiven"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                large
            ></v-rating>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-btn
              text
              @click="btnRateDialogNoThanks"
          >
            {{ $t('rating.nothanks') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="btnRateDialogRate"
          >
            {{ $t('rating.ratenow') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Coffee dialog -->
    <v-dialog v-model="showcoffeedialog">
      <v-card
          class="elevation-16 mx-auto"
          max-width="450"
      >
        <v-card-title>
          {{ $t('coffee.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('coffee.text') }}
          <v-container fluid>
            <v-row>
              <v-spacer />
              <v-col>
                <v-img src="/coffee_200x200.png"></v-img>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-btn
              text
              @click="btnCoffeeDialogNoThanks"
              color="grey"
          >
            {{ $t('coffee.nothanks') }}
          </v-btn>
          <v-btn
              color="green lighten-1"
              @click="btnCoffeeDialogOK"
              href="https://www.paypal.com/paypalme/ralfcwilke/4,90" target="_blank"
              outline
              class="ma-2 white--text"
          >
            <v-icon color="red">
              mdi-heart
            </v-icon>
            {{ $t('coffee.goodidea') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import 'flag-icon-css/css/flag-icon.min.css';
import store from "./store";


export default {
  name: 'App',
  components: {
  },

  created() {
    if (!(store.getters.dismissedHintLegend)) {
      this.showHintLegend();
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_rating);
    clearInterval(this.timer_coffeehint);
  },
  mounted() {
    this.getRatings();
    this.$root.$on('onConnectChange', (status) => {
      this.mqtt_constatus = status;
      //console.log('MQTT connected');
    });
    this.$root.$on('onbtnLegendMappressed', (status) => {
      this.showLegend = true;
    });
    this.$root.$on('onisTXing', (status) => {
      this.PTT_Anystatus = status.ptt;
      this.calltxing = status.callsign;
      if (!this.firstTX && this.PTT_Anystatus) {
        if (!this.$store.getters.rated) {
          // seconds
          let min = 120;
          let max = 240;
          this.startIntervalRating(1000 * (Math.floor(Math.random() * (max - min + 1) + min)));
        }
        this.firstTX = true;
        if (!this.$store.getters.coffeehintseen) {
          // seconds
          let min = 10*60;
          let max = 20*60;
          this.startIntervalCoffee(1000 * (Math.floor(Math.random() * (max - min + 1) + min)));
        }
      }
      //console.log('Any PTT status in main changed');
    });
    this.$root.$on('onUserCountChange', (status) => {
      this.usercount = status;
    });
    this.$root.$on('onconnectedStatisticsChange', (status) => {
      this.connectedStatistics = status;
    });
    this.$root.$on('onPageReloadNotification', () => {
        this.showPageReloadAlert();
    });
  },
  computed: {
    averageRating() {
      if (this.ratings.length > 0) {
        let ratingvaluesum = 0;
        let ratingcountsum = 0;
        for( var i = 0; i < this.ratings.length; i++ ){
          ratingcountsum += parseInt( this.ratings[i], 10 );
          ratingvaluesum += ((i+1) * parseInt( this.ratings[i], 10 ));
        }
        let avg = Math.round(ratingvaluesum/ratingcountsum * 10)/10;
        return (avg);
      } else {
        return (0);
      }
    }
  },
  methods: {
    bntcookieok() {
      this.$store.commit('changecookiehintok', {
        cookiehintok: true
      });
      this.showcookiehint = false;
    },
    showRatings(){
      this.getRatings();
      this.showratingsummary = true;
    },
    getRatings() {
      this.axios.get(this.$store.getters.url_base + '/api/ratings')
      .then((response) => {
        //console.log('ratings', response.data);
        this.ratings = response.data;
      });
    },
    startIntervalRating: function (delay) {
      this.timer_rating = setInterval(() =>{
        this.showratedialog = true;
        clearInterval(this.timer_rating);
      }, delay);
    },
    startIntervalCoffee: function (delay) {
      this.timer_coffeehint = setInterval(() =>{
        this.showcoffeedialog = true;
        clearInterval(this.timer_coffeehint);
      }, delay);
    },
    btnRateDialogRate() {
      this.showratedialog = false;
      this.$store.commit('changerated', {
        rated: true
      });
      this.axios.post(this.$store.getters.url_base + '/api/rate', {
        rating: this.ratinggiven
      }).then((response) => {
        console.log('rate response', response.data);
      });
    },
    btnRateDialogNoThanks() {
      this.showratedialog = false;
      this.$store.commit('changerated', {
        rated: true
      });
    },
    btnCoffeeDialogOK() {
      this.showcoffeedialog = false;
      this.$store.commit('changecoffeehintseen', {
        coffeehintseen: true
      });
    },
    btnCoffeeDialogNoThanks() {
      this.showcoffeedialog = false;
      this.$store.commit('changecoffeehintseen', {
        coffeehintseen: true
      });
    },
    showHintLegend() {
      this.$swal({
        title: this.$t('hintlegend.title'),
        text: this.$t('hintlegend.text'),
        input: 'checkbox',
        icon: 'info',
        inputValue: 1,
        inputPlaceholder: this.$t('hintlegend.dontshowagain'),
        confirmButtonText: this.$t('hintlegend.understood')
      }).then((result) => {
        if (result.isConfirmed && result.value == 1) {
          this.$store.commit('dismissedHintLegend', {
            dismissedHintLegend: true
          });
        }
      })
    },
    showPageReloadAlert() {
      this.$swal({
        title: this.$t('reload_page.title'),
        text: this.$t('reload_page.text'),
        iconColor: 'orange',
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: this.$t('reload_page.btn_ok'),
        denyButtonText: this.$t('reload_page.btn_deny'),
        confirmButtonColor: 'green',
        denyButtonColor: 'red',
        focusConfirm: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
    },
    changeLanguage(lang) {
      this.$root.$i18n.locale = lang;
      //// Whatever may be right here?
      //this.$root.$forceUpdate();
      //this.$forceUpdate();
      this.$vuetify.lang.current = lang;
      this.$store.commit('changeLanguage', {
        language: lang
      });
      this.languages[0].text = this.$i18n.t('language.de');
      this.languages[1].text = this.$i18n.t('language.en');
    },
  },
  data() {
    return {
      ratings: [0, 0, 0, 0, 0],
      showratingsummary: false,
      firstTX: false,
      timer_rating: null,
      timer_coffeehint: null,
      ratinggiven: 3,
      showratedialog: false,
      showcoffeedialog: false,
      connectedStatistics: {},
      showLegend: false,
      mqtt_constatus: false,
      PTT_Anystatus: false,
      calltxing: '',
      usercount: 0,
      loadingOverlay: false,
      languages: [
        {
          text: this.$i18n.t('language.de'),
          value: 'de',
          icon: 'de'
        },
        {
          text: this.$i18n.t('language.en'),
          value: 'en',
          icon: 'gb'
        }
      ],
      selectedLanguage: 'de',
      showcookiehint: !this.$store.getters.cookiehintok
    }
  }
};
</script>
