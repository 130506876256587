<template>
  <v-container fluid>
    <v-row
        align="center"
        justify="center"
    >
      <v-col cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>{{ $t('logout.title') }}</v-card-title>
          <v-card-text>
            {{ $t('logout.text') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                link exact to="/"
            >
              {{ $t('logout.btngotomain') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    // Delete all cookie data
    this.$store.commit('logout');
    console.log('Logout commited to store.');
  }
}
</script>

<style scoped>

</style>