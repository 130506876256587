<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title>
        <v-container>
          <v-row>
            <v-col>
              {{ $t('newsedit.title') }}
            </v-col>
            <v-spacer/>
            <v-col>
              <v-btn
                color="primary"
                @click="addNews()"
              >
                <v-icon>
                  mdi-newspaper-plus
                </v-icon>
                {{ $t('newsedit.add') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-progress-linear v-show="isLoadingNews" slot="progress" color="blue" indeterminate></v-progress-linear>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isFormValid" ref="form">
          <v-container>
            <v-row
                v-for="(item, index) in currentNews" :key="index"
            >
              <v-col sm="2">
                <v-menu
                    v-model="datepickermenu[currentNews.indexOf(item)]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="computedDateListFormatted[currentNews.indexOf(item)]"
                        :label="$t('newsedit.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="validationRules.date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="item.date"
                      no-title
                      @input="datepickermenu[currentNews.indexOf(item)] = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="9">
                <v-text-field
                    v-model="item.text"
                    :label="$t('newsedit.text')"
                    prepend-icon="mdi-newspaper-variant-outline"
                    counter="150"
                    :rules="validationRules.text"
                ></v-text-field>
              </v-col>
              <!-- Actions -->

              <v-col sm="1">
                <v-btn
                    icon
                    small
                    color="red"
                    @click="deleteNews(index)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn
                    v-if="index != 0"
                    icon
                    small
                    color="orange"
                    @click="moveNewsUp(index)"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                    v-if="index != (currentNews.length -1)"
                    icon
                    small
                    color="orange"
                    @click="moveNewsDown(index)"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="!isFormValid"
            color="red accent-2"
            @click="btnSave"
        >
          {{ $t('general.save') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import moment from 'moment';

export default {
  name: "NewsEdit",
  computed: {
    validationRules() {
      return {
        'date': [
          v => (!!v && v.length > 0)  || this.$t('formvalidation.isrequired', {fieldname: this.$t('newsedit.date')}),
        ],
        'text': [
          v => !!v || this.$t('formvalidation.isrequired', {fieldname: this.$t('newsedit.text')}),
          v => (v && v.length >= 5) || this.$t('formvalidation.underlength', {
            fieldname: this.$t('newsedit.text'),
            count: '5'
          }),
          v => (v && v.length <= 150) || this.$t('formvalidation.overlength', {
            fieldname: this.$t('newsedit.text'),
            count: '150'
          })
        ]
      }
    },
    computedDateListFormatted() {
      //console.log('Called computedDateListFormatted');
      //console.log(this.currentNews);
      let DateListFormatted = [];
      for (let i = 0; i < this.currentNews.length; i++) {
        if (this.currentNews[i].date) {
          DateListFormatted.push(moment(this.currentNews[i].date).format('DD.MM.YYYY'));
        } else {
          DateListFormatted.push('');
        }
      }
      //console.log(DateListFormatted);
      return DateListFormatted;
    }
  },
  created() {

  },
  mounted() {
    this.getNews();
  },
  methods: {
    btnSave() {
      //console.log('Save button pressed');
      //console.log(this.currentNews);
      this.axios.post('/api/news',
          this.currentNews,
          {
            headers: {
              'Authorization': 'Basic ' + this.$store.getters.auth
            }
          })
          .then((response) => {
            console.log('POST news ok', this.currentNews, response);
          });
    },
    addNews(){
      this.currentNews.unshift({date:'', text: ''});
    },
    moveNewsUp(index) {
      let tmp = this.currentNews[index - 1];
      this.$set(this.currentNews, index - 1, this.currentNews[index]);
      this.$set(this.currentNews, index, tmp);
    },
    moveNewsDown(index) {
      let tmp = this.currentNews[index + 1];
      this.$set(this.currentNews, index + 1, this.currentNews[index]);
      this.$set(this.currentNews, index, tmp);
    },
    deleteNews(index) {
      if (this.currentNews.length > 0) {
        this.currentNews.splice(index,1);
      }
    },
    applydate(index, date){
      console.log(this.datepickermenu);
      this.datepickermenu[index] = false;
      this.currentNews[index].date = date;
      console.log(this.datepickermenu);
    },
    getNews() {
      this.isLoadingNews = true;
      this.axios.get('/api/news')
          .then((response) => {
            this.currentNews = response.data
            console.log('news', this.currentNews);
            for (let i = 0; i < this.currentNews.length; i++) {
              this.datepickermenu.push(false);
            }
            this.isLoadingNews = false;
          });
    }
  },
  data() {
    return {
      isLoadingNews: false,
      currentNews : [],
      datepickermenu: [],
      isFormValid: false
    };
  }
}
</script>

<style scoped>

</style>