<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('relais.uploadimage.title') }}: {{ callsign }}
      </v-card-title>
      <v-row align="center" justify="center"><div align="center" width="500">
        <vue-avatar
            :width=300
            :height=250
            :rotation="rotation"
            :scale="scale"
            :borderRadius=0
            ref="vueavatar"
            @vue-avatar-editor:image-ready="onImageReady"
        >
        </vue-avatar></div>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col sm="4">
          <v-slider
              v-model="scale"
              :label="$t('relais.uploadimage.zoom')"
              min="1"
              max="6"
              step="0.02"
              thumb-label
              width="400"
          >
          </v-slider>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col sm="4">
          <v-slider
              v-model="rotation"
              :label="$t('relais.uploadimage.rotation')"
              min="0"
              max="360"
              step="1"
              thumb-label
              width="400"
          >
          </v-slider>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <img ref="image">
      <v-card-actions>
        <v-btn
            v-on:click="avatarSaveClicked"
            :disabled="!imageUploaded"
        >
          {{ $t('general.upload') }}
        </v-btn>
        <v-btn
            color="primary"
            link exact to="/relais"
        >
          {{ $t('general.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
import {VueAvatar} from 'vue-avatar-editor-improved';

export default {
  name: "UploadImage",
  components: {
    VueAvatar
  },
  computed: {

  },
  created() {

  },
  mounted() {

    console.log('URL ID:',this.callsign);
  },
  methods: {
    createBlob(dataURL) {
      var BASE64_MARKER = ';base64,';
      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        let parts = dataURL.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
      }
      var parts = dataURL.split(BASE64_MARKER);
      var contentType = parts[0].split(':')[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);
      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    avatarSaveClicked() {
        var img = this.$refs.vueavatar.getImage();
        var rawImage = this.createBlob(img.toDataURL('image/jpeg'));
        this.axios.post('/api/relais/photo/' + this.callsign,
            rawImage,
            {
              headers: {
                'Authorization': 'Basic ' + this.$store.getters.auth,
                'Content-Type': 'image/jpeg'
              }
            }
        ).then(r => {
          console.log('Photo uploaded: ' + r.status);
        }).catch(e => console.log(e));
        this.$router.push('/relais');
    },
    onImageReady() {
      console.log('called onImageReady');
      this.imageUploaded = true;
      this.scale = 1;
      this.rotation = 0;
    },
  },
  data() {
    return ({
      rotation: 0,
      scale: 1,
      callsign: this.$route.params.id.toUpperCase(),
      imageUploaded: false
    });
  }
}
</script>

<style scoped>

</style>