<template>
  <v-container fluid>
    <v-card

        class="mx-auto"
    >
      <v-card-text>
        <template>
          <v-data-table
              :headers="headers"
              :items="allRelais"
              :search="search"
              sort-by="callsign"
          >
            <v-progress-linear v-show="isLoadingRelais" slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
                  {{ $t('relais.overview.title') }}
                </v-toolbar-title>

                <v-divider
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('general.search')"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>

                <v-tooltip top v-if="!$store.getters.credentialsPresent" color="red lighten-1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{ $t('general.new') }}
                  </v-btn>
                  </template>
                  <span>{{ $t('general.loginasadmin') }}</span>
                </v-tooltip>
                <v-btn
                    v-if="$store.getters.credentialsPresent"
                    color="primary"
                    @click="btnNewItem"
                >
                  {{ $t('general.new') }}
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Tweak action column-->
            <template v-slot:item.actions="{ item }">
              <!-- Edit icon active -->
              <v-icon
                  v-if="$store.getters.credentialsPresent"
                  small
                  class="mr-2"
                  @click="btnEditItem(item.callsign)"
                  color="purple darken-2"
              >
                mdi-pencil
              </v-icon>

              <!-- Edit icon forbidden -->
              <v-tooltip top v-if="!$store.getters.credentialsPresent" color="red lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      small
                      color="grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('general.loginasadmin') }}</span>
              </v-tooltip>


              <!-- Upload photo icon active -->
              <v-icon
                  v-if="$store.getters.credentialsPresent"
                  small
                  class="mr-2"
                  @click="btnUploadPhotoItem(item.callsign)"
                  color="orange darken-2"
              >
                mdi-image-plus
              </v-icon>

              <!-- Upload photo icon forbidden -->
              <v-tooltip top v-if="!$store.getters.credentialsPresent" color="red lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      small
                      color="grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-image-plus
                  </v-icon>
                </template>
                <span>{{ $t('general.loginasadmin') }}</span>
              </v-tooltip>


              <!-- Delete icon active -->
              <v-icon
                  v-if="$store.getters.credentialsPresent"
                  small
                  @click="btnDeleteItem(item)"
                  color="red"
              >
                mdi-delete
              </v-icon>
              <!-- Delete icon forbidden -->
              <v-tooltip top v-if="!$store.getters.credentialsPresent" color="red lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      small
                      color="grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t('general.loginasadmin') }}</span>
              </v-tooltip>
            </template>

            <!-- Tweak qrgtx column-->
            <template v-slot:item.qrgtx="{ item }">
              <v-chip
                  color="teal"
                  text-color="white"
                  label
              >
                {{ beautifyQRG(item.qrgtx, 4) }} MHz
              </v-chip>
            </template>

            <!-- Tweak qrgrx column-->
            <template v-slot:item.qrgrx="{ item }">
              <v-chip
                  color="teal"
                  text-color="white"
                  label
              >
                {{ beautifyQRG(item.qrgrx, 4) }} MHz
              </v-chip>
            </template>

            <!-- Tweak ctcss column-->
            <template v-slot:item.ctcss="{ item }">
              <v-chip
                  v-if="item.ctcss"
                  color="teal"
                  text-color="white"
                  label
              >
                {{ beautifyQRG(item.ctcss, 1) }} Hz
              </v-chip>
            </template>

            <!-- Tweak photoPresent column-->
            <template v-slot:item.photoPresent="{ item }">
              <v-img
                  v-if="item.photoPresent"
                  :src="getPhotoURL(item.callsign)"
                  height="32"
                  width="38"
              >
              </v-img>
              <v-icon
                  v-if="!item.photoPresent"
                  color="red"
              >
                mdi-camera-off
              </v-icon>
            </template>

            <!-- Tweak enabled column-->
            <template v-slot:item.enabled="{ item }">
              <v-icon
                  v-if="item.enabled"
                  color="green"
              >
                mdi-eye-outline
              </v-icon>
              <v-icon
                  v-if="!item.enabled"
                  color="red"
              >
                mdi-eye-off-outline
              </v-icon>
            </template>
            <!-- Tweak eirp_watt column-->
            <template v-slot:item.eirp_watt="{ item }">
              <v-chip
                  v-if="(item.eirp_watt) && (item.eirp_watt > 0)"
                  color="teal"
                  text-color="white"
                  label
              >
                {{ Math.round(item.eirp_watt) }} W
              </v-chip>
              <v-icon
                  v-else
                  color="red"
              >
                mdi-database-off-outline
              </v-icon>
            </template>
            <!-- Tweak antHeight_m column-->
            <template v-slot:item.antHeight_m="{ item }">
              <v-chip
                  v-if="(item.antHeight_m) && (item.antHeight_m >= 0)"
                  color="teal"
                  text-color="white"
                  label
              >
                {{ Math.round(item.antHeight_m) }} m
              </v-chip>
              <v-icon
                  v-else
                  color="red"
              >
                mdi-database-off-outline
              </v-icon>
            </template>

            <!-- Tweak  LastSimulationTimestamp column-->
            <template v-slot:item.LastSimulationTimestamp="{ item }">
              {{ formatisodate(item.LastSimulationTimestamp) }}
            </template>

            <template v-slot:no-data>
              No CATS
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";


export default {
  name: "RelaisTable",
  computed: {
    headers() {
      return [
        {
          text: this.$t('relais.headers.callsign'),
          align: 'start',
          sortable: false,
          value: 'callsign',
        },
        {
          text: this.$t('relais.headers.description'),
          align: 'start',
          sortable: false,
          value: 'desc',
        },
        {
          text: this.$t('relais.headers.frequencytx'),
          align: 'center',
          sortable: false,
          value: 'qrgtx',
        },
        {
          text: this.$t('relais.headers.frequencyrx'),
          align: 'center',
          sortable: false,
          value: 'qrgrx',
        },
        {
          text: this.$t('relais.headers.ctcss'),
          align: 'center',
          sortable: false,
          value: 'ctcss',
        },
        {
          text: this.$t('relais.headers.photopresent'),
          align: 'start',
          sortable: false,
          value: 'photoPresent',
        },
        {
          text: this.$t('relais.headers.enabled'),
          align: 'center',
          sortable: false,
          value: 'enabled',
        },
        {
          text: this.$t('relais.headers.eirp'),
          align: 'center',
          sortable: false,
          value: 'eirp_watt',
        },
        {
          text: this.$t('relais.headers.antheight'),
          align: 'center',
          sortable: false,
          value: 'antHeight_m',
        },
        {
          text: this.$t('relais.headers.lastsimulation'),
          align: 'start',
          sortable: false,
          value: 'LastSimulationTimestamp',
        },
        {
          text: this.$t('general.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created() {

  },
  mounted() {
    this.getallRelais();
  },
  methods: {
    btnNewItem() {
      this.$router.push('/relais/new');
    },
    btnEditItem(callsign) {
      this.$router.push('/relais/edit/' + callsign);
    },
    btnUploadPhotoItem(callsign) {
      this.$router.push('/relais/uploadimage/' + callsign);
    },
    btnDeleteItem(item) {
      console.log('btnDeleteItem', item);
      this.$swal({
        title: this.$t('relais.delete.title', {fieldname: item.callsign}),
        text: this.$t('relais.delete.text'),
        iconColor: 'red',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t('relais.delete.btndelete'),
        cancelButtonText: this.$t('relais.delete.btncancel'),
        confirmButtonColor: 'red',
        cancelButtonColor: 'grey',
        focusConfirm: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete soll ausgeführt werden');
          this.axios.delete('/api/relais/' + item.callsign,
              {
                headers: {
                  'Authorization': 'Basic ' + this.$store.getters.auth
                }
              })
              .then((response) => {
                console.log('DELETE relais ok', response);
                this.getallRelais();
              });
        }
      })
    },
    formatisodate(isodate) {
      let date = moment(isodate);
      return(date.format('DD.MM.YY HH:mm'));
    },
    getPhotoURL(callsign) {
      let imagesize = 'mob';
     // console.log('getPhotoURL', callsign);
      return ('/photos/' + callsign.toUpperCase() + '_' + imagesize + '.jpg');
    },
    beautifyQRG(item, decimals) {
      return (item.toFixed(decimals));
    },
    getallRelais() {
      this.isLoadingRelais = true;
      this.allRelais = [];
      this.allRelaisNames = [];
      this.axios.get('/api/relais')
          .then((response) => {
            let res = response.data
            for (var callsign in res) {
              let relaisdict = res[callsign];
              relaisdict['callsign'] = callsign;
              this.allRelais.push(relaisdict);
            }
            this.isLoadingRelais = false;
            console.log('getrelais', this.allRelais);
          });
    }
  },
  data() {
    return {
      allRelais: [],
      search: '',
      isLoadingRelais: false,
    };
  }
}
</script>

<style scoped>

</style>