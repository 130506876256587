import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import store from '../store';
import i18n from '../i18n';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

// set initial locale
i18n.locale = store.getters.language;

Vue.use(Vuetify, {
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    }
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
