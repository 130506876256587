<template>
  <!-- Infos für den Redakteur
  - Texte in i18n/de.json und i18n/en.json einbauen
  - Entsprechend Beispiel des Titels einbinden
  - Gestaltungselemente: https://vuetifyjs.com/en/components/cards und weitere Seiten dort
  -->
  <div>
    <v-card>
      <v-card-title>
        {{ $t('aboutproject.title') }}
      </v-card-title>
      <v-card-text>
        <p class="text-justify">
          {{ $t('aboutproject.texts.1') }}
        </p>
        <p class="text-justify">
          {{ $t('aboutproject.texts.2') }}
        </p>
        <ul>
          <li>{{ $t('aboutproject.texts.3-1') }}</li>
          <li>{{ $t('aboutproject.texts.3-2') }}</li>
          <li>{{ $t('aboutproject.texts.3-3') }}</li>
          <li>{{ $t('aboutproject.texts.3-4') }}</li>
          <li>{{ $t('aboutproject.texts.3-5') }}</li>
          <li>{{ $t('aboutproject.texts.3-6') }}</li>
          <li>{{ $t('aboutproject.texts.3-7') }}</li>
        </ul>
        <br />

        <h3 class="text-justify">
          {{ $t('aboutproject.texts.4') }}
        </h3>
        <p class="text-justify">
          {{ $t('aboutproject.texts.5') }}
        </p>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-img src="/repeater_connected_26.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.repeater_connected_26') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/repeater_connected_49.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.repeater_connected_49') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/repeater_connected_2649.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.repeater_connected_2649') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/repeater_EcholinkNotIn26.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.repeater_EcholinkNotIn26') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/repeater_NotTG26orTG49.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.repeater_NotTG26orTG49') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/link_connected_26.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.link_connected_26') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/link_connected_49.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.link_connected_49') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/link_connected_2649.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.link_connected_2649') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="/activerxing.png"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('legend.rxing') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <p class="text-justify">
          {{ $t('aboutproject.texts.7') }}
        </p>
        <h2 class="text-justify">
          {{ $t('aboutproject.texts.9') }}
        </h2>
        <p class="text-justify">
          {{ $t('aboutproject.texts.10') }}
        </p>
        <ul>
          <li>{{ $t('aboutproject.texts.11-1') }}</li>
          <li>{{ $t('aboutproject.texts.11-2') }}</li>
          <li>{{ $t('aboutproject.texts.11-3') }}</li>
        </ul>
        <br />
        <h2 class="text-justify">
          {{ $t('aboutproject.texts.12') }}
        </h2>
        <p v-if="$store.getters.amateurradiocontext" class="text-justify">
          {{ $t('aboutproject.texts.13') }} {{ $t('aboutproject.texts.14') }} {{ $t('aboutproject.texts.15') }}
        </p>
        <p v-if="!$store.getters.amateurradiocontext" class="text-justify">
          {{ $t('aboutproject.texts.13') }}
          <a href="https://www.ralfwilke.com" target="_blank_"> {{ $t('aboutproject.texts.14') }} </a>
            {{ $t('aboutproject.texts.15') }}
        </p>
        <p class="text-justify">
          {{ $t('aboutproject.texts.16') }}
        </p>
        <p class="text-justify">
          {{ $t('aboutproject.texts.17') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AboutProject"
}
</script>

<style scoped>

</style>