<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('privacy.title') }}
      </v-card-title>
      <v-card-text>
        <h3 class="text-justify">
          {{ $t('privacy.texts.cookie.title') }}
        </h3>
        <br/>
        <p class="text-justify">
          {{ $t('privacy.texts.cookie.intro') }}
        </p>
        <ul>
          <li>{{ $t('privacy.texts.cookie.1') }}</li>
          <li>{{ $t('privacy.texts.cookie.2') }}</li>
          <li>{{ $t('privacy.texts.cookie.3') }}</li>
          <li>{{ $t('privacy.texts.cookie.4') }}</li>
        </ul>
        <br/>
        <h3 class="text-justify">
          {{ $t('privacy.texts.dsgvo.title') }}
        </h3>
        <br/>
        <p class="text-justify">
          {{ $t('privacy.texts.dsgvo.text') }}
        </p>
        <ul>
          <li>{{ $t('privacy.texts.dsgvo.usage.status') }}</li>
          <li>{{ $t('privacy.texts.dsgvo.usage.initialload') }}</li>
        </ul>
        <br/>
        <p class="text-justify">
          {{ $t('privacy.texts.dsgvo.outro') }}
        </p>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped>

</style>