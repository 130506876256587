<template>
  <v-container fluid>
    <v-row
        align="center"
        justify="center"
    >
      <v-col cols="12" sm="12" md="6">

          <v-card>
            <v-card-title>{{ $t('login.title') }}</v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="form">
                  <v-text-field
                      :label="$t('general.username')"
                      required
                      name="username"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="username"
                      :rules="validationRules.username"
                  >
                  </v-text-field>

                  <v-text-field
                      id="password"
                      :label="$t('general.password')"
                      name="password"
                      prepend-icon="mdi-key"
                      type="password"
                      v-model="password"
                      required
                      :rules="validationRules.password"
                  >
                  </v-text-field>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                  link exact to="/"
                  color="red"
              >
                {{ $t('login.cancel') }}
              </v-btn>
              <v-btn
                  color="primary"
                  @click="submitForm"
                  type="submit"
                  :disabled="!isFormValid"
              >
                {{ $t('login.loginbutton') }}
              </v-btn>
            </v-card-actions>
          </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      isLoadingUsernames: true,
      username: '',
      password: '',
      isFormValid: false
    };
  },
  mounted() {
  },
  methods: {
    submitForm() {
      //event.preventDefault();
      // request given users data to validate credentials
      axios.post('/api/login', {
        username: this.username,
        password: this.password
      }, {
        auth: {
          username: this.username,
          password: this.password
        }
      }).then(response => {
        if (response.status == 200) {
          // success --> save credentials and go to home
          console.log('Authenticated');
          this.$store.commit('login', {
            user: this.username,
            auth: btoa(this.username + ':' + this.password),
          });
          // Inform about logged in
          console.log('Emitting LoggedIn');
          this.$root.$emit('LoggedIn');
        }
        this.$router.push('/relais');
      }).catch(error => {
        console.log('Error on Authentication');
        console.log(error);
        this.$store.commit('logout');
      });
    }
  },
  computed: {
    validationRules() {
      return {
        'username': [
          v => !!v || this.$t('formvalidation.isrequired', { fieldname: this.$t('general.username') }),
          v => (!!v && v.length >= 3) || this.$t('formvalidation.underlength', {
            fieldname: this.$t('general.username'),
            count: '3'
          })
        ],
        'password': [
          v => (!!v && v.length > 0) || this.$t('formvalidation.isrequired', { fieldname: this.$t('general.password') })
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>