import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistedState from 'vuex-persistedstate';
import axios from "axios";

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        language: 'de',
        packageVersion: process.env.VUE_APP_VERSION || '0',
        dismissedHintLegend: false,
        rated: false,
        amateurradiocontext: true,
        url_mqtt: '',
        url_tiles: '',
        url_base: '',
        user: null,
        auth: null,
        cookiehintok: false,
        coffeehintseen2023: false
    },
    getters: {
        user: state => {
            return state.user;
        },
        username: state => {
            return state.user ? state.user : 'Guest';
        },
        auth: state => {
            return state.auth;
        },
        isAdmin: state => {
            return (state.user == "admin");
        },
        credentialsPresent: state => {
            return (state.user !== null && state.auth !== null);
        },
        amateurradiocontext: (state) => {
            return state.amateurradiocontext;
        },
        url_tiles: (state) => {
            return state.url_tiles;
        },
        url_mqtt: (state) => {
            return state.url_mqtt;
        },
        url_base: (state) => {
            return state.url_base;
        },
        appVersion: (state) => {
            return state.packageVersion;
        },
        language: state => {
            return state.language;
        },
        languageFlag: state => {
            if (state.language === 'en') return 'gb';
            return state.language;
        },
        dismissedHintLegend: state => {
            return state.dismissedHintLegend;
        },
        rated: state => {
            return state.rated;
        },
        coffeehintseen: state => {
            return state.coffeehintseen2023;
        },
        cookiehintok: state => {
            return state.cookiehintok;
        }
    },
    mutations: {
        changeAmateurradioContext(state, payload) {
            state.amateurradiocontext = payload.amateurradiocontext;
        },
        changeURLs(state, payload) {
            //console.log('changeURLs', payload);
            state.url_mqtt = payload.urls.mqtt;
            state.url_tiles = payload.urls.tiles;
            state.url_base = payload.urls.base;
        },
        changeLanguage(state, payload) {
            state.language = payload.language;
        },
        dismissedHintLegend(state, payload) {
            state.dismissedHintLegend = payload.dismissedHintLegend;
        },
        changerated(state, payload) {
            state.rated = payload.rated;
        },
        changecoffeehintseen(state, payload) {
            state.coffeehintseen2023 = payload.coffeehintseen;
        },
        changecookiehintok(state, payload) {
            state.cookiehintok = payload.cookiehintok;
        },
        login(state, payload) {
            console.log('Executing mutation login');
            state.user = payload.user;
            state.auth = payload.auth;
            axios.defaults.headers.common['Authorization'] = 'Basic ' + payload.auth;
            console.log('axios default auth set');
        },
        logout(state) {
            state.user = null;
            state.auth = null;
            delete(axios.defaults.headers.common['Authorization']);
            console.log('mutation logout');
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        VuexPersistedState({
            key: 'nordwestserver-live',
            paths: [
                'language',
                'dismissedHintLegend',
                'rated',
                'user',
                'auth',
                'cookiehintok',
                'coffeehintseen2023'
            ]
        })
    ]
});
