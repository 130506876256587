<template>
  <v-card>
    <marquee-text :repeat="5">
      {{ newstext }}
    </marquee-text>

    <div id="map-wrapper" style="height: calc(100vh - 150px); height: calc(100dvh - 150px); ">
    <l-map
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 100%; width: 100%; z-index: 1;"
      >

      <l-control-zoom position="bottomleft"  ></l-control-zoom>

      <!-- Full screen control -->
      <l-control-fullscreen position="bottomleft"
          :options="getFullscreenOptions"
      />

      <l-control
        position="bottomleft"
      >
        <v-card
            width="32"
            height="32"
            class="leaflet-bar text-align:center text-decoration-none"
        >
          <v-icon
              class="mx-1 mt-1"
              @click="btnLegendMappressed()"
          >
            mdi-map-legend
          </v-icon>
        </v-card>
      </l-control>

      <!-- Current RXing station TG 26 -->
      <l-control
          position="topright"
          v-if="PTT26"
      >
        <v-container>
          <v-card elevation="3" color="green lighten-4">
            <v-card-subtitle>
              {{ callsign26 }} NW
            </v-card-subtitle>
            <v-img
                v-if="isPhotoPresentCurrent26"
                :src="getPhotoURLcurrent26"
            >
            </v-img>
            <v-card-text>{{ getDesc(callsign26) }}<br/>{{ getQRGs(callsign26) }}<br/>{{ getCTCSS(callsign26) }}</v-card-text>
          </v-card>
        </v-container>
      </l-control>

      <!-- Current RXing station TG 49 -->
      <l-control
          position="topleft"
          v-if="PTT49"
      >
        <v-container>
          <v-card elevation="3" color="blue lighten-4">
            <v-card-subtitle>
              {{ callsign49 }} Hansa
            </v-card-subtitle>
            <v-img
                v-if="isPhotoPresentCurrent49"
                :src="getPhotoURLcurrent49"
            >
            </v-img>
            <v-card-text>{{ getDesc(callsign49) }}<br/>{{ getQRGs(callsign49) }}<br/>{{ getCTCSS(callsign49) }}</v-card-text>
          </v-card>
        </v-container>
      </l-control>

      <!-- FAB Selection -->
      <l-control
          position="bottomright"
      >
        <v-speed-dial
            v-model="fab"
            direction="left"
            transition="slide-x-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  fab
                  dark
                  small
                  color="grey"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectFAB('audiostream')"
              >
                <v-icon>mdi-play-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('fab.audiostream') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectFAB('ptthistory')"
              >
                <v-icon>mdi-list-box-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('fab.ptthistory') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectFAB('audiochart')"
              >
                <v-icon>mdi-chart-line</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('fab.chart') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  fab
                  dark
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectFAB('None')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('fab.hide') }}</span>
          </v-tooltip>
        </v-speed-dial>
      </l-control>

      <!-- PTT History -->
      <l-control
          v-if="showptthistory"
          position="bottomleft"
      >
        <v-container>
          <v-card elevation="3" width="300px">
            <v-card-text class="py-3">{{ $t('ptthistory.title') }}</v-card-text>
            <v-card-text class="py-3 px-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('ptthistory.callsign') }}
                    </th>
                    <th class="text-left">
                    {{ $t('ptthistory.lastptt') }}
                    </th>
                    <th class="text-left">
                      {{ $t('ptthistory.TG') }}
                    </th>
                    <th class="text-left">
                      {{ $t('ptthistory.status') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in ptthistorydata"
                      :key="item.index"
                      :bgcolor = "item.TG == '26' ? '#c8e6c9' : '#bbdefb'"
                  >
                    <td><small>{{ item.callsign }}</small></td>
                    <td><small>{{ formatedlastptt(item.timestamp) }}</small></td>
                    <td><small>{{ item.TG }}</small></td>
                    <td><small>
                      <v-icon
                          v-if="item.hamnet"
                          color="green"
                      >
                        mdi-antenna
                      </v-icon>
                      <v-icon
                          v-if="!item.hamnet"
                          color="pink"
                      >
                        mdi-web
                      </v-icon>
                      <v-icon
                          v-if="item.echolink"
                          color="red"
                      >
                        mdi-alpha-e-circle-outline
                      </v-icon>
                    </small>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-container>
      </l-control>

      <!--- Chart --->
      <l-control
          v-if="showaudiochart"
          position="bottomleft"
      >
        <v-container>
          <v-card elevation="3" width="300px" height="300px">
            <v-card-text>{{ $t('fab.chart') }}</v-card-text>
            <v-card-text>
              <ejs-chart :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis'
                         :chartArea='chartArea' :width='width' height="200">
                <e-series-collection>
                  <e-series
                      :dataSource='seriesData'
                      type='Spline'
                      xName='x'
                      yName='y'
                      name='NF'
                      width=2
                  >
                  </e-series>
                </e-series-collection>
              </ejs-chart>
            </v-card-text>
          </v-card>
        </v-container>
      </l-control>


      <!--- Stream --->
      <l-control
          v-if="showaudiostream"
          position="bottomleft"
      >
        <v-container>
          <v-card elevation="0" width="300px" height="300px">
            <v-card-text>{{ $t('fab.audiostream') }}</v-card-text>
            <v-card-text class="py-3 px-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('audiostream.descr') }}
                    </th>
                    <th class="text-left">
                      {{ $t('audiostream.url') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in streams"
                      :key="item.index"
                      :bgcolor = "item.tg == '26' ? '#c8e6c9' : '#bbdefb'"
                  >
                    <td><small>{{ item.descr }}</small></td>
                    <td><small>
                      <v-btn
                          :href="item.url"
                          target="_blank"
                          size="small"
                      >
                        {{ $t('audiostream.urltxt') }}
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </small></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-container>
      </l-control>

      <l-tile-layer
          :url="this.$store.getters.url_tiles"
          :attribution="attribution"
      />
      <l-marker
          v-for="(item) in markers"
          :key="item.call"
          :lat-lng="item.coordinates"
          :icon="item.icon"
          :visible="item.visible"
      >
        <l-popup>
          <v-card elevation="0">
            <v-card-title>
              {{ item.call }}
            </v-card-title>
            <v-img
                v-if="isPhotoPresentPopup(item.call)"
                :src="getPhotoURLPopup(item.call)"
               :width="getPhotoWidth"
            >
            </v-img>
            <v-card-text>{{ getDesc(item.call) }}<br/>{{ getQRGs(item.call) }}<br/>{{ getCTCSS(item.call) }}</v-card-text>
            <v-card-actions>
              <v-btn
                  :href="item.url"
                  target="_blank"
              >
                {{ $t('map.popup.website') }}
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </l-popup>
      </l-marker>

      <!-- Server Markers Static --->
      <l-marker
          v-for="(item) in server_markers"
          :key="item.desc"
          :lat-lng="item.coordinates"
          :icon="item.icon"
          visible
      >
        <l-popup>
          {{ item.desc }}
        </l-popup>
      </l-marker>
    </l-map>
  </div>
  </v-card>
  <!--{{ $vuetify.breakpoint.name }} {{ $vuetify.breakpoint.width }}
  Last message from Server received {{ timeSinceLastKeepalive }} seconds ago, NF-Pegel {{ uvmeter }}
  -->
</template>

<script>
var mqtt = require('precompiled-mqtt');
var mqttclient;
import { latLng } from "leaflet";
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LControl, LControlZoom} from "vue2-leaflet";
//import { LMap, LTileLayer, LMarker, LPopup, LControlAttribution, LControl, LImageOverlay} from "vue2-leaflet";
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.js';
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css';
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import moment from "moment";
import { uuid } from 'vue-uuid';
import { ColumnSeries, Category, SplineSeries, DateTime} from "@syncfusion/ej2-vue-charts";

export default {
  provide: {
    chart: [ColumnSeries, Category, SplineSeries, DateTime]
  },
  name: 'NWSLive',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    //LControlAttribution,
    LControlZoom,
    LControl,
    LControlFullscreen
  },
  mounted() {
    //console.log(this.$vuetify.breakpoint.name);
    //this.updateMarkers(this.simulatedMQTT);
    this.startup();
  },
  created() {
    // Generate UUID
    this.uuid = uuid.v1();
  },
  beforeDestroy () {
    clearInterval(this.timer_keepalive);
    clearInterval(this.timer_sendUserStats);
    mqttclient.end();
  },
  computed: {
    getFullscreenOptions() {
      return (
          {
            title: {
              'true': this.$t('map.fullscreen.window'),
              'false': this.$t('map.fullscreen.fullscreen')
            }
          }
      );
    },
    getPhotoWidth() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return (300);
      } else {
        return (120);
      }
    },
    timeSinceLastKeepalive: function () {
     //console.log('computed');
      return this.last_moment_keepalive_formated;
    },
    isPhotoPresentCurrent26() {
      //console.log('present', this.callsign);
      if (this.callsign26.length > 0) {
        let key = this.callsign26.toUpperCase();
        if (this.relays[key]) {
          //console.log('isPhotoPresentCurrent', this.relays[key].photoPresent);
          return (this.relays[key].photoPresent);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getPhotoURLcurrent26() {
      let imagesize ='';
      if (this.$vuetify.breakpoint.mdAndUp) {
        imagesize = 'dsktp';
      } else {
        imagesize = 'mob';
      }
      if (this.PTT26) {
        return ('/photos/' + this.callsign26.toUpperCase() + '_'+ imagesize + '.jpg');
      } else {
        return ('/photos/Banner_Nordwest_' + imagesize + '.jpg');
      }
    },
    isPhotoPresentCurrent49() {
      //console.log('present', this.callsign);
      if (this.callsign49.length > 0) {
        let key = this.callsign49.toUpperCase();
        //console.log('isPhotoPresentCurrent', this.relays[key].photoPresent);
        if (this.relays[key]) {
          return (this.relays[key].photoPresent);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getPhotoURLcurrent49() {
      let imagesize ='';
      if (this.$vuetify.breakpoint.mdAndUp) {
        imagesize = 'dsktp';
      } else {
        imagesize = 'mob';
      }
      if (this.PTT49) {
        return ('/photos/' + this.callsign49.toUpperCase() + '_'+ imagesize + '.jpg');
      } else {
        return ('/photos/Banner_Nordwest_' + imagesize + '.jpg');
      }
    }
  },
  methods: {
    getNews() {
      this.axios.get('/api/news')
          .then((response) => {
            let newsresponse = response.data
            console.log('news', newsresponse);
            this.newstext = '++++ ';
            for (let i = 0; i < newsresponse.length; i++) {
              this.newstext += moment(newsresponse[i].date).format('DD.MM.YYYY') + ': ' + newsresponse[i].text;
              if (i < (newsresponse.length - 1)) {
                this.newstext += ' ++++ ';
              }
            }
            this.newstext += ' ++++';
          });
    },
    getCTCSS(callsign){
      if (callsign != '' && this.relays[callsign] && this.relays[callsign]['ctcss']) {
          return ('CTCSS: ' + this.relays[callsign]['ctcss'] + ' Hz');
      } else {
        return ('');
      }
    },
    getQRGs(callsign){
      if (callsign != '' && this.relays[callsign]) {
        //return ('QRGs: ' + this.relays[callsign]['qrgtx'] + ' MHz / ' + this.relays[callsign]['qrgrx'] + ' MHz');
        return ('QRG: ' + this.relays[callsign]['qrgtx'] + ' MHz');
      } else {
        return ('');
      }
    },
    getDesc(callsign) {
      if (callsign != '' && this.relays[callsign]) {
        return (this.relays[callsign]['desc']);
      } else {
        return ('');
      }
    },
    startmqtt() {
      console.log('startmqtt executed');
      console.log(mqttclient, typeof mqttclient);
      if (typeof mqttclient !== 'undefined') {
        console.log('Ending obsolete MQTT Client');
        mqttclient.end();
      }
      mqttclient  = mqtt.connect(this.$store.getters.url_mqtt);
      mqttclient.on('connect', this.onConnect);
      mqttclient.on('message', this.onMessage);
      mqttclient.on('reconnect', this.onReconnect);
      mqttclient.on('close', this.onDisconnect);
      this.startIntervalKeepalive();
      this.startIntervalSendUserStats();
    },
    startup() {
      this.isLoadingRelais = true;
      this.axios.get('/api/relais')
          .then((response) => {
            this.relays = response.data
            console.log('startup, all Relais from database', this.relays);
            this.startmqtt();
            this.makeMarkers();
          });
      this.axios.get('/stream/streams.json')
          .then((response) => {
            this.streams = response.data
            console.log('startup, all Streams from database', this.streams);
          });
      this.getNews();
    },
    btnLegendMappressed() {
      this.$root.$emit('onbtnLegendMappressed', null);
    },
    selectFAB(selected) {
      if (selected == 'None') {
        this.showptthistory = false;
        this.showaudiochart = false;
        this.showaudiostream = false;
      } else if (selected == 'ptthistory') {
        this.showaudiochart = false;
        this.showaudiostream = false;
        this.$nextTick(function () {
          this.showptthistory = true;
        });
      } else if (selected == 'audiochart') {
        this.showptthistory = false;
        this.showaudiostream = false;
        this.$nextTick(function () {
          this.showaudiochart = true;
        });
      } else if (selected == 'audiostream') {
        this.showptthistory = false;
        this.showaudiochart = false;
        this.$nextTick(function () {
          this.showaudiostream = true;
        });
      }
    },
    processNFvalue(value) {
      this.lastaudiovalues.push(value);
      if (this.lastaudiovalues.length > 40) {
        this.lastaudiovalues.shift();
      }
      //console.log('processNFvalue', this.callsign);
      if (this.callsign) {
        let temp = JSON.parse(JSON.stringify(this.seriesData));
        temp.unshift({x: 0, y: value});
        if (temp.length > 40) {
          temp.pop();
        }
        let i = 0;
        let timePerDiv = (1 / 16000) * 512 * 10;
        for (i = 0; i < temp.length; i++) {
          temp[i].x = - i * timePerDiv;
        }
        this.seriesData = JSON.parse(JSON.stringify(temp));
      }
    },
    getPhotoURLPopup(callsign) {
      let imagesize = '';
      if (this.$vuetify.breakpoint.mdAndUp) {
        imagesize = 'dsktp';
      } else {
        imagesize = 'mob';
      }
      //console.log('getPhotoURLPopup', callsign);
      return ('/photos/' + callsign.toUpperCase() + '_' + imagesize + '.jpg');
    },
    isPhotoPresentPopup(callsign) {
      //console.log('isPhotoPresentPopup', callsign);
      if (callsign.length > 0) {
        let key = callsign.toUpperCase();
        //console.log('isPhotoPresentPopup', this.relays[key].photoPresent);
        return (this.relays[key].photoPresent);
      } else {
        return false;
      }
    },
    startIntervalKeepalive: function () {
      this.timer_keepalive = setInterval(() =>{
        //console.log('updating ticker')
        if (this.last_moment_keepalive) {
          this.last_moment_keepalive_formated = Math.round(moment.duration(moment().diff(this.last_moment_keepalive)).asSeconds());
        }
      }, 500);
    },
    startIntervalSendUserStats: function () {
      this.timer_sendUserStats = setInterval(() =>{
        //console.log('updating ticker')
        if (mqttclient.connected) {
          let data2send = {}
          data2send[this.uuid] = moment().toISOString();
          mqttclient.publish('userstats/clients', JSON.stringify(data2send));
        }
      }, 3000);
    },
    onConnect: function(connack) {
      console.log('Connected MQTT');
      mqttclient.subscribe('svxlink/NF');
      mqttclient.subscribe('svxlink/status_15112022');
      mqttclient.subscribe('keepalive');
      mqttclient.subscribe('userstats/count');
      mqttclient.subscribe('websitecontrol');
      mqttclient.subscribe('clienthandler/' + this.uuid);
      mqttclient.publish('clienthandler/new', JSON.stringify({uuid: this.uuid}));
      console.log('MQTT connect:', connack);
      this.mqtt_connected = true;
      this.$root.$emit('onConnectChange', this.mqtt_connected);
    },
    onReconnect: function () {
      console.log('MQTT Reconnected');
    },
    onMessage: function(topic, message) {
      //console.log('Got topic: ' + topic + ' Message: ' + message.toString());
      let messagestr = message.toString();
      let messageJSON = JSON.parse((messagestr));
      switch(topic) {
        case 'svxlink/status_15112022':
          //console.log('Got topic: ' + topic + ' Message: ' + message.toString());
          this.whoisTxing(messageJSON);
          this.updateMarkers(messageJSON);
          break;
        //case 'svxlink/status':
        //  //console.log(messageJSON);
        //  this.updateMarkers(messageJSON);
        //  break;
        case 'clienthandler/' + this.uuid:
          console.log('received startup update', messageJSON);
          this.whoisTxing(messageJSON);
          this.updateMarkers(messageJSON);
          break;
        case 'svxlink/NF':
          //console.log(messageJSON);
          this.uvmeter = messageJSON['val'];
          this.processNFvalue(messageJSON['val']);
          break;
        case 'keepalive':
          //console.log(messageJSON);
          this.last_moment_keepalive = moment();
          //console.log('MQTT:', this.last_moment_keepalive.fromNow());
          //console.log('lastmoment', this.last_moment_keepalive);
          break;
        case 'userstats/count':
          //console.log(messageJSON);
          this.usercount = messageJSON['users'];
          //console.log(this.usercount);
          //console.log('MQTT:', this.last_moment_keepalive.fromNow());
          //console.log('lastmoment', this.last_moment_keepalive);
          this.$root.$emit('onUserCountChange', this.usercount);
          break;
        case 'websitecontrol':
          if (messageJSON['reload']) {
            this.$root.$emit('onPageReloadNotification');
          }
          break;
      }
    },
    onDisconnect: function() {
      console.log('MQTT disconneted');
      this.mqtt_connected = false;
      this.$root.$emit('onConnectChange', this.mqtt_connected);
    },
    formatedlastptt(timestamp) {
      return (timestamp.format("HH:mm:ss"));
    },
    whoisTxing(updatedMQTTdata) {
      for (let updatedcall in updatedMQTTdata) {
        //console.log(updatedcall, updatedMQTTdata[updatedcall]);
        if (updatedcall === 'DB0WTV-L') { continue; }
        if (updatedMQTTdata[updatedcall] && updatedMQTTdata[updatedcall]['PTT_Status'] &&
            updatedMQTTdata[updatedcall]['PTT_Status'] === true) {
          // Check if was previously TXing
          if (this.pttStatusPrevious[updatedcall] && this.pttStatusPrevious[updatedcall] === true) {
            console.log(updatedcall, " was already txing");
            continue;
          } else {
            this.pttStatusPrevious[updatedcall] == true;
          }

          this.$root.$emit('onisTXing', { ptt: true, callsign: updatedcall, TG: updatedMQTTdata.TG });
          console.log('whoisTxing', updatedcall);
          if (updatedMQTTdata[updatedcall]['TG'] === 26) {
            this.PTT26 = true;
            this.callsign26 = updatedcall;
          }
          if (updatedMQTTdata[updatedcall]['TG'] === 49) {
            this.PTT49 = true;
            this.callsign49 = updatedcall;
          }
          // Add to history list
          this.ptthistorydata.unshift({
            "callsign": updatedcall,
            "timestamp": moment(),
            "hamnet": updatedMQTTdata[updatedcall]['hamnet'],
            "TG": updatedMQTTdata[updatedcall]['TG'],
            "echolink": updatedMQTTdata[updatedcall]['echolink'],
            "index": 100
          })
          // Keep last 5 entries
          if (this.ptthistorydata.length > 5) {
            this.ptthistorydata.pop();
          }
          // Reindex
          for (let i = 0; i < this.ptthistorydata.length; i++) {
            this.ptthistorydata[i].index = i;
          }
          //console.log("PTThistorydata");
          //console.log(this.ptthistorydata);
        } else {
          this.pttStatusPrevious[updatedcall] = false;
        }
      }

      //console.log('Vor Cleanup PTT 26', this.PTT26);
      //console.log('Vor Cleanup PTT 49', this.PTT49);
      // Clean up PTT Status
      if (this.PTT26) {
        //console.log('Cleanup 26 was true');
        let isStillPTT26 = false;
        for (let updatedcall in updatedMQTTdata) {
          if (updatedMQTTdata[updatedcall]['PTT_Status'] && updatedMQTTdata[updatedcall]['TG'] === 26) {
            isStillPTT26 = true;
          }
        }
        if (!isStillPTT26) {
          this.PTT26 = false;
          this.callsign49 = '';
        }
      }
      if (this.PTT49) {
        let isStillPTT49 = false;
        for (let updatedcall in updatedMQTTdata) {
          if (updatedMQTTdata[updatedcall]['PTT_Status'] && updatedMQTTdata[updatedcall]['TG'] === 49) {
            isStillPTT49 = true;
          }
        }
        if (!isStillPTT49) {
          this.PTT49 = false;
          this.callsign49 = '';
        }
      }
      // If neither PTT26 nor PTT49 are true, nobody is txing
      if (!this.PTT26 && !this.PTT49) {
        this.$root.$emit('onisTXing', {ptt: false, callsign: '', TG: -1});
      }
    },
    makeMarkers() {
      this.markers = [];
      for (let callkey in this.relays) {
        if (this.relays[callkey].enabled) {
          this.markers.push({
            'call': callkey,
            'coordinates': latLng(this.relays[callkey].Lat, this.relays[callkey].Long),
            'desc': this.relays[callkey].desc,
            'icon': this.icons.TowerNotConnectedNoIP,
            'visible': false,
            'url': this.relays[callkey].url
          });
        }
      }
    },
    getMarkerIndexFromCallsign(callsign) {
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].call === callsign) {
          return i;
        }
      }
      console.log('Not found ', callsign, ' in markers');
      return -1;
    },
    updateMarkerIconRepeaterorLink(callsign, iconCategory) {
      switch (iconCategory) {
        case 'TG26':
          if (this.isLink(callsign)) {
            this.updateOrAddMarkerIconLink(callsign, this.icons.PointTG26);
          } else {
            this.updateMarkerIconRepeater(callsign, this.icons.TowerTG26);
          }
          break;
        case 'TG49':
          if (this.isLink(callsign)) {
            this.updateOrAddMarkerIconLink(callsign, this.icons.PointTG49);
          } else {
            this.updateMarkerIconRepeater(callsign, this.icons.TowerTG49);
          }
          break;
        case 'TG2649':
          if (this.isLink(callsign)) {
            this.updateOrAddMarkerIconLink(callsign, this.icons.PointTG2649);
          } else {
            this.updateMarkerIconRepeater(callsign, this.icons.TowerTG2649);
          }
          break;
        case 'NotTG26orTG49':
          if (this.isLink(callsign)) {
            this.makeMarkerInvisible(callsign);
          } else {
            this.updateMarkerIconRepeater(callsign, this.icons.TowerNotTG26orTG49);
          }
          break;
        case 'NotConnected':
          if (this.isLink(callsign)) {
            this.makeMarkerInvisible(callsign);
          } else {
            this.updateMarkerIconRepeater(callsign, this.icons.TowerEcholinkNotIn26);
          }
          break;
      }
    },
    updateMarkerIconRepeater(callsign, icon) {
      this.markers[this.getMarkerIndexFromCallsign(callsign)].icon = icon;
      this.markers[this.getMarkerIndexFromCallsign(callsign)].visible = true;
    },
    updateOrAddMarkerIconLink(callsign, icon) {
      if (this.getMarkerIndexFromCallsign(callsign) === -1) {
        // Marker is not present, add
        this.markers.push({
          'call': callsign,
          'coordinates': latLng(this.relays[callsign].Lat, this.relays[callsign].Long),
          'desc': this.relays[callsign].desc,
          'icon': icon,
          'visible': true,
          'url': this.relays[callsign].url
        });
      } else {
        this.markers[this.getMarkerIndexFromCallsign(callsign)].icon = icon;
        this.markers[this.getMarkerIndexFromCallsign(callsign)].visible = true;
      }
    },
    setMarkerTXing(callsign, TG) {
      if (TG === 26) {
        this.markers[this.getMarkerIndexFromCallsign(callsign)].icon = this.icons.pulsingOnAir26;
      } else if (TG === 49) {
        this.markers[this.getMarkerIndexFromCallsign(callsign)].icon = this.icons.pulsingOnAir49;
      }
    },
    removeNotConnectedMarkers(updatedMQTTdata) {
      for (let i = 0; i < this.markers.length; i++) {
        if (!updatedMQTTdata[this.markers[i].call]) {
          this.updateMarkerIconRepeaterorLink(this.markers[i].call, 'NotConnected');
        }
      }
    },
    makeMarkerInvisible(callsign) {
      this.markers[this.getMarkerIndexFromCallsign(callsign)].visible = false;
    },
    isLink(callsign) {
      let link_pattern = /.*-L$/g;
      return (link_pattern.test(callsign));
    },
    updateConnectedStatistics(metadata) {
      this.connectedStatistics = {
        'echolink': 0,
        'TG26': 0,
        'TG49': 0,
        'hamnet': 0,
        'total': 0
      };

      if (metadata.echolink) { this.connectedStatistics.echolink += 1; }
      if (metadata.hamnet) { this.connectedStatistics.hamnet += 1; }
      if (metadata.TG === 26 || (metadata['TG_Monitor'] && metadata['TG_Monitor'].includes(26))) { this.connectedStatistics.TG26 += 1; }
      if (metadata.TG === 49 || (metadata['TG_Monitor'] && metadata['TG_Monitor'].includes(49))) { this.connectedStatistics.TG49 += 1; }
      this.connectedStatistics.total = this.connectedStatistics.TG26 +  this.connectedStatistics.TG49;
      this.$root.$emit('onconnectedStatisticsChange', this.connectedStatistics);
    },
    isInDatabase(callsign) {
      // Check if callsign is in relay database
      for (let relaydbcallsign in this.relays) {
        if (relaydbcallsign === callsign) {
          return true;
        }
      }
      return false;
    },
    updateMarkers(updatedMQTTdata) {
      //this.whoisTxing(updatedMQTTdata);
      //console.log(updatedMQTTdata);
      this.connectedStatistics = {
        'echolink': 0,
        'TG26': 0,
        'TG49': 0,
        'hamnet': 0,
        'total': 0
      };
      /*
      Icons:
      ======

      Repeater:
      ---------

      TowerNotConnectedNoIP -> Tower schwarz
      TowerEcholinkNotIn26 -> Tower rot
      TowerNotTG26orTG49 -> Tower unten grün, oben rot
      TowerTG26 -> Tower grün
      TowerTG49 -> Tower blau
      TowerTG2649 -> Tower grün/blau

      Links:
      ------

      PointTG26 -> Punkt grün
      PointTG49 -> Punkt blau
      PointTG2649 -> Punkt grün/blau

      Zuordnung PTT false:
      ====================

      "TG": 26, TG_Monitor: <egal>,                           "echolink": false  -> TowerTG26
      "TG": 49, TG_Monitor: <egal>,                           "echolink": false -> TowerTG49
      "TG": 0, TG_Monitor: [26,[weitere ohne 26]],            "echolink": false -> TowerTG26
      "TG": 0, TG_Monitor: [49,[weitere ohne 49]],            "echolink": false -> TowerTG49
      "TG": 0, TG_Monitor: [26,49,[weitere ohne 26 und 49]],  "echolink": false -> TowerTG2649
      "TG": 0, TG_Monitor: [leer oder (!26 && !49],           "echolink": false -> TowerNotTG26orTG49
      "TG": !26 && !49], TG_Monitor: [leer oder (!26 && !49], "echolink": false -> TowerNotTG26orTG49

      "TG": 26,                                               "echolink": true -> TowerTG26
      "TG": 49,                                              "echolink": true -> TowerTG49


      Zuordnung PTT true:
      ====================
      "TG":26", "echolink": true -> Pulsing26
      "TG":49", "echolink": true -> Pulsing49

      <nicht da, Repeater> -> TowerEcholinkNotIn26
      <nicht da, Link> -> Visibiltiy false

       */


      //console.log("Show all mqtt callsigns");
      //for (let updatedcall in updatedMQTTdata) {
        //console.log("show all callsigns", updatedcall);
      //}

      for (let callsign in updatedMQTTdata) {
        if (callsign === 'DB0WTV-L') { continue; }
        let metadata = updatedMQTTdata[callsign];
        if (!this.isInDatabase(callsign)) { continue; }

        // is TXing
        if (metadata.PTT_Status) {
          this.setMarkerTXing(callsign, metadata.TG);
          continue;
        }

        // is !TXing and !echolink
        if (!metadata.echolink) {
          // Not Echolink

          // "TG": 26, TG_Monitor: <egal>,                           "echolink": false  -> TG26
          if (metadata.TG === 26) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG26');
            this.connectedStatistics.TG26 += 1;
          }

          // "TG": 49, TG_Monitor: <egal>,                           "echolink": false -> TG49
          else if (metadata.TG === 49) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG49');
            this.connectedStatistics.TG49 += 1;
          }

          // "TG": 0, TG_Monitor: [26,[weitere ohne 26]],            "echolink": false -> TG26
          else if (metadata.TG === 0 && metadata['TG_Monitor'].includes(26) && !metadata['TG_Monitor'].includes(49)) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG26');
            this.connectedStatistics.TG26 += 1;
          }

          // "TG": 0, TG_Monitor: [49,[weitere ohne 49]],            "echolink": false -> TG49
          else if (metadata.TG === 0 && metadata['TG_Monitor'].includes(49) && !metadata['TG_Monitor'].includes(26)) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG49');
            this.connectedStatistics.TG49 += 1;
          }

          // "TG": 0, TG_Monitor: [26,49,[weitere ohne 26 und 49]],  "echolink": false -> TG2649
          else if (metadata.TG === 0 && metadata['TG_Monitor'].includes(26) && metadata['TG_Monitor'].includes(49)) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG2649');
            this.connectedStatistics.TG26 += 1;
            this.connectedStatistics.TG49 += 1;
          }

          // "TG": 0, TG_Monitor: [leer oder (!26 && !49],           "echolink": false -> NotTG26orTG49
          else if (metadata.TG === 0 && !metadata['TG_Monitor'].includes(26) && !metadata['TG_Monitor'].includes(49)) {
            this.updateMarkerIconRepeaterorLink(callsign, 'NotTG26orTG49');
          }

          // "TG": !26 && !49], TG_Monitor: [leer oder (!26 && !49], "echolink": false -> NotTG26orTG49
          else if (metadata.TG !== 26 && metadata.TG !== 49 &&
              !metadata['TG_Monitor'].includes(26) && !metadata['TG_Monitor'].includes(49)) {
            this.updateMarkerIconRepeaterorLink(callsign, 'NotTG26orTG49');
          }
        } else {
          // Echolink

          // "TG": 26,                                               "echolink": true -> TG26
          if (metadata.TG === 26) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG26');
            this.connectedStatistics.TG26 += 1;
            this.connectedStatistics.echolink += 1;
          }

          // "TG": 49,                                               "echolink": true -> TG49
          if (metadata.TG === 49) {
            this.updateMarkerIconRepeaterorLink(callsign, 'TG49');
            this.connectedStatistics.TG49 += 1;
            this.connectedStatistics.echolink += 1;
          }

          // "TG": 0,                                                "echolink": true -> NotTG26orTG49
          if (metadata.TG === 0) {
            this.updateMarkerIconRepeaterorLink(callsign, 'NotTG26orTG49');
            this.connectedStatistics.echolink += 1;
          }
        }

        //this.updateConnectedStatistics(metadata)

        this.connectedStatistics.total = this.connectedStatistics.TG26 +  this.connectedStatistics.TG49;
        this.$root.$emit('onconnectedStatisticsChange', this.connectedStatistics);
      }
      // all MQTT input processed

      this.removeNotConnectedMarkers(updatedMQTTdata);
    }
  },
  data() {
    return {
      newstext: '',
      overlaylist: [],
      fab: false,
      photosPresent: [],
      snackbar_reload: true,
      showptthistory: false,
      showaudiochart: false,
      showaudiostream: false,
      //Initializing Primary X Axis
      primaryXAxis: {
        valueType: 'Double',
        labelFormat: '{value} sec',
        interval: 5,
        majorGridLines: { width: 1 },
        labelIntersectAction: 'Rotate90'
      },
      //Initializing Primary Y Axis
      primaryYAxis: {
        labelFormat: '{value} dBFS',
        lineStyle: { width: 0 },
        majorTickLines: { width: 1 },
        minorTickLines: { width: 2 },
        minimum: -50,
        maximum: 0,
        interval: 10
      },
      chartArea: {
        border: {
          width: 0
        }
      },
      width : '100%',
      uvmeter : 0,
      uuid: uuid.v1(),
      relays: require('../relays.json'),
      streams: require('../streams.json'),
      zoom: 7,
      center: latLng(52.7, 8),
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      showMap: true,
      iconSize: [24, 24],
      mqtt_connected: false,
      last_moment_keepalive: null,
      last_moment_keepalive_formated: '',
      timer_keepalive: null,
      timer_sendUserStats: null,
      usercount: 0,
      PTT26: false,
      PTT49: false,
      callsign26 : '',
      callsign49 : '',
      lastaudiovalues: [],
      seriesData: [],
      ptthistorydata: [],
      pttStatusPrevious: {},
      icons: {
        'TowerEcholinkNotIn26': L.icon({
          iconUrl: '/repeater_EcholinkNotIn26.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -25]
        }),
        'TowerNotTG26orTG49': L.icon({
          iconUrl: '/repeater_NotTG26orTG49.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -25]
        }),
        'TowerTG26': L.icon({
          iconUrl: '/repeater_connected_26.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -25]
        }),
        'TowerTG49': L.icon({
          iconUrl: '/repeater_connected_49.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -25]
        }),
        'TowerTG2649': L.icon({
          iconUrl: '/repeater_connected_2649.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -25]
        }),
        'PointTG26': L.icon({
          iconUrl: '/link_connected_26.png',
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          popupAnchor: [0, -15]
        }),
        'PointTG49': L.icon({
          iconUrl: '/link_connected_49.png',
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          popupAnchor: [0, -15]
        }),
        'PointTG2649': L.icon({
          iconUrl: '/link_connected_2649.png',
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          popupAnchor: [0, -15]
        }),
        'pulsingOnAir26': L.icon.pulse({
          iconSize: [12, 12],
          iconAnchor: [6, 6],
          color: 'green',
          fillColor: 'green',
          animate: true
        }),
        'pulsingOnAir49': L.icon.pulse({
          iconSize: [12, 12],
          iconAnchor: [6, 6],
          color: 'blue',
          fillColor: 'blue',
          animate: true
        })
      },
      markers: [],
      server_markers: [
        {
          coordinates: latLng(53.0732131, 8.7783038),
          desc: 'NordWestLink Server 1',
          icon: L.icon({
            iconUrl: '/server.png',
            iconSize: [12, 12],
            iconAnchor: [10, 10],
            popupAnchor: [0, -15]
          })
        },
        {
          coordinates: latLng(52.2696623, 8.0420556),
          desc: 'NordWestLink Server 2',
          icon: L.icon({
            iconUrl: '/server.png',
            iconSize: [12, 12],
            iconAnchor: [10, 10],
            popupAnchor: [0, -15]
          })
        }
      ],
      connectedStatistics : {
        'echolink': 0,
        'TG26': 0,
        'TG49': 0,
        'hamnet': 0,
        'total': 0
      }
    }
  }
}
</script>
