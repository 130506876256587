import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import App from './App.vue'
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import 'leaflet/dist/leaflet.css';
import UUID from "vue-uuid";
import axios from 'axios'
import VueAxios from 'vue-axios'
import MarqueeText from 'vue-marquee-text-component'

import { ChartPlugin, LineSeries, ColumnSeries, SplineSeries, ChartAnnotation, Category,  Legend, Tooltip } from "@syncfusion/ej2-vue-charts";
// SweetAlert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(ChartPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(UUID);
Vue.component('marquee-text', MarqueeText)

Vue.config.productionTip = false;

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// Change Document title according to router target
router.afterEach((to) => {
  // use current view as title-source
  document.title = i18n.t(to.meta.titleTranslationKey);
});


// Determine if this is hamnet or internet and set state accordingly

var urls_config = require('./urls.json');
//console.log('main.js urls_config', urls_config);
if ((window.location.origin.indexOf('live.nordwestlink.net') > -1) ||
    (window.location.origin.indexOf('192.168.17.3') > -1)) {
  // Internet of Local dev VM
  store.commit('changeAmateurradioContext', {
    amateurradiocontext: false
  });
  store.commit('changeURLs', {
    urls: {
      mqtt: urls_config.internet.mqtt,
      tiles: urls_config.internet.tiles,
      base: urls_config.internet.base
    }
  });
} else {
  store.commit('changeAmateurradioContext', {
    amateurradiocontext: true
  });
  store.commit('changeURLs', {
    urls: {
      mqtt: urls_config.hamnet.mqtt,
      tiles: urls_config.hamnet.tiles,
      base: urls_config.hamnet.base
    }
  });
}

export default {
  provide: {
    chart: [LineSeries, ColumnSeries, SplineSeries, ChartAnnotation, Category,  Legend, Tooltip]
  }
};

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
